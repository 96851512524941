var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Form } from 'antd';
// @ts-ignore
import Button from '@fp-suite/core-ui/src/components/Button';
import Dropdown from '@fp-suite/core-ui/src/components/Dropdown';
import { useTranslation } from 'react-i18next';
import { setPrefferedLanguage } from '../redux/authSlice';
import { useDispatch } from 'react-redux';
var ChangeLanguage = function (_a) {
    var setIsLoading = _a.setIsLoading;
    var SUPPORTED_LANGUAGES = ['en', 'es'];
    var StoredValue = localStorage.getItem('i18nextLng');
    var initialLanguage = SUPPORTED_LANGUAGES.includes(StoredValue) ? StoredValue : 'en';
    var _b = useState(""), selectedLanguage = _b[0], setSelectedLanguage = _b[1];
    var _c = useTranslation(), i18n = _c.i18n, t = _c.t;
    var dispatch = useDispatch();
    return (_jsx("div", __assign({ className: 'px-4 sm:px-8 lg:px-16 pt-1 lg:pt-3 pb-4 sm:h-auto md:h-screen w-full sm:w-4/5 md:w-3/5 lg:w-2/5' }, { children: _jsxs("div", __assign({ className: 'bg-white p-5 pb-0.5 rounded-lg shadow-sm' }, { children: [_jsx("h1", __assign({ className: 'font-bold text-base mb-7' }, { children: t('Change Language') }), void 0), _jsx("div", __assign({ className: 'w-11/12 mx-auto' }, { children: _jsxs(Form, __assign({ name: 'change_language', initialValues: {
                            language: initialLanguage
                        }, onFinish: function (_a) {
                            var language = _a.language;
                            return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0: return [4 /*yield*/, dispatch(setPrefferedLanguage({
                                                userId: localStorage.getItem('userId'),
                                                preferenceLanguage: language
                                            }))
                                            // setIsLoading(true)
                                            // localStorage.setItem('i18nextLng', language)
                                        ];
                                        case 1:
                                            _b.sent();
                                            // setIsLoading(true)
                                            // localStorage.setItem('i18nextLng', language)
                                            i18n.changeLanguage(language);
                                            return [2 /*return*/];
                                    }
                                });
                            });
                        } }, { children: [_jsx(Form.Item, __assign({ name: 'language', rules: [
                                    {
                                        required: true,
                                        message: t('Please select language')
                                    }
                                ] }, { children: _jsx(Dropdown, { data: [
                                        {
                                            key: 'en',
                                            value: 'English'
                                        },
                                        {
                                            key: 'es',
                                            value: 'Spanish'
                                        }
                                    ], title: t('Select language'), 
                                    // isDisabled={isOrgSelected !== 'true' ? true : false}
                                    isDisabled: false, placeholder: 'Select One', value: selectedLanguage, onChange: function (val) { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            setSelectedLanguage(val);
                                            return [2 /*return*/];
                                        });
                                    }); } }, void 0) }), void 0), _jsx(Form.Item, { children: _jsx("div", __assign({ className: 'flex flex-col' }, { children: _jsx(Button, { buttonText: t('Change Language') }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0)] }), void 0) }), void 0));
};
export default ChangeLanguage;
