var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Dropdown from '@fp-suite/core-ui/src/components/Dropdown';
import SelectBtn from '../components/SelectBtn';
import MainHeading from '../components/MainHeading';
import { useTranslation } from 'react-i18next';
import { getFacilityList, getActiveFacility, setActiveFacility, userSelector, changeActiveOrg, getUserAssociatedOrganization, getActiveOrg } from '../redux/authSlice';
var TaskSelect = function (_a) {
    var setIsLoading = _a.setIsLoading;
    var dispatch = useDispatch();
    var history = useHistory();
    var t = useTranslation().t;
    var _b = useSelector(userSelector), singlePickBtnDisabled = _b.singlePickBtnDisabled, multiPickBtnDisabled = _b.multiPickBtnDisabled, moveToSortingBtnDisabled = _b.moveToSortingBtnDisabled, sortingBtnDisabled = _b.sortingBtnDisabled, activeOrgToggled = _b.activeOrgToggled, organizationsList = _b.organizationsList, currentOrg = _b.currentOrg;
    var _c = useState([]), facilityList = _c[0], setFacilityList = _c[1];
    var _d = useState(''), facility = _d[0], setFacility = _d[1];
    var isOrgSelected = localStorage.getItem('isOrgSelected');
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, dispatch(getUserAssociatedOrganization())];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, dispatch(getActiveOrg())];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, dispatch(getFacilityList({ setFacilityList: setFacilityList, history: history }))];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, dispatch(getActiveFacility({
                                setFacility: setFacility
                            }))];
                    case 4:
                        _a.sent();
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, []); // eslint-disable-line
    return (_jsx("div", __assign({ className: 'px-4 sm:px-8 lg:px-16 pt-1 lg:pt-3 pb-16 h-screen w-full sm:w-4/5 md:w-3/5 lg:w-2/5 mx-auto' }, { children: _jsxs(_Fragment, { children: [_jsx(MainHeading, { heading: t('Select Task') }, void 0), _jsxs("div", __assign({ className: 'py-7 px-10 w-full rounded-xl shadow-sm bg-white' }, { children: [_jsxs("div", __assign({ className: 'mb-4' }, { children: [_jsx(Dropdown, { data: organizationsList, title: t('Select an Organization'), 
                                    // isDisabled={isOrgSelected !== 'true' ? true : false}
                                    isDisabled: false, placeholder: 'Select One', value: currentOrg, onChange: function (val) { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            dispatch(changeActiveOrg({ orgId: val }));
                                            dispatch(getFacilityList({ setFacilityList: setFacilityList, history: history }));
                                            return [2 /*return*/];
                                        });
                                    }); } }, void 0), currentOrg === 'Select One' && (_jsx("h1", __assign({ className: 'font-medium text-base' }, { children: "Please select an organization to continue." }), void 0))] }), void 0), _jsx(Dropdown, { data: facilityList, title: t('Select a Warehouse'), 
                            // isDisabled={isOrgSelected !== 'true' ? true : false}
                            isDisabled: false, placeholder: 'Select One', value: facility, onChange: function (val) { return __awaiter(void 0, void 0, void 0, function () {
                                var sideBarNavData;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: 
                                        // setFacility(val)
                                        return [4 /*yield*/, dispatch(setActiveFacility({
                                                facilityId: val,
                                                setFacility: setFacility
                                            }))
                                            // await dispatch(
                                            //   getFacilityLocationContainers({
                                            //     forMoveToSorting: true,
                                            //     setBtnDisabled: 'setMoveToSortingBtnDisabled',
                                            //     functionCall: 'taskSelect'
                                            //   })
                                            // )
                                            // await dispatch(
                                            //   getFacilityLocationContainers({
                                            //     forMoveToSorting: false,
                                            //     setBtnDisabled: 'setSortingBtnDisabled',
                                            //     functionCall: 'taskSelect'
                                            //   })
                                            // )
                                        ];
                                        case 1:
                                            // setFacility(val)
                                            _a.sent();
                                            sideBarNavData = {
                                                pickSingles: singlePickBtnDisabled,
                                                pickMultis: multiPickBtnDisabled,
                                                moveToSorting: moveToSortingBtnDisabled,
                                                sorting: sortingBtnDisabled
                                            };
                                            return [2 /*return*/];
                                    }
                                });
                            }); } }, void 0), _jsx("div", { className: 'border-b border-gray-500 my-6' }, void 0), _jsxs("div", { children: [_jsx("h1", __assign({ className: 'font-bold text-xs mb-0.5 sm:mb-1.5' }, { children: t('General Tasks') }), void 0), _jsx(SelectBtn, { isDisabled: currentOrg && facility ? false : true, text: t('Receiving'), onClick: function () { return history.push('/receiving'); }, pointerDisabled: false }, void 0), _jsx(SelectBtn, { isDisabled: currentOrg && facility ? false : true, text: t('Return'), onClick: function () { return history.push('/return'); }, pointerDisabled: false }, void 0), _jsx(SelectBtn, { isDisabled: currentOrg && facility ? false : true, onClick: function () {
                                        history.push('/moveAsset');
                                    }, text: t('Move Asset'), pointerDisabled: false }, void 0)] }, void 0), _jsx("div", { className: 'border-b border-gray-500 my-6' }, void 0), _jsxs("div", { children: [_jsx("h1", __assign({ className: 'font-bold text-xs mb-0.5 sm:mb-1.5' }, { children: t('Shipping Tasks') }), void 0), _jsx(SelectBtn, { isDisabled: currentOrg && facility ? false : true, onClick: function () { return history.push('/picking'); }, text: t('Picking') }, void 0), _jsx(SelectBtn, { isDisabled: currentOrg && facility ? false : true, onClick: function () { return history.push('/packing'); }, text: t('Packing') }, void 0)] }, void 0)] }), void 0)] }, void 0) }), void 0));
};
export default TaskSelect;
