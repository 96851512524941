import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Image } from 'antd';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next'; // Importing i18next
import fulfillmentPlus_logo from '../images/fulfillmentPlus_logo.svg';
var HandheldLogo = function (_a) {
    var width = _a.width, clickable = _a.clickable;
    var t = useTranslation().t;
    var history = useHistory();
    var isMobileDevice = useMediaQuery({
        query: '(max-device-width: 640px)'
    });
    return (_jsx(Image, { className: 'cursor-pointer', onClick: function () {
            if (clickable) {
                if (!window.location.href.split('/')[3]) {
                    // do nothing
                }
                else {
                    var isConfirmed = window.confirm(t('Changes that you made may not be saved.'));
                    if (isConfirmed) {
                        history.push('/');
                    }
                }
            }
        }, width: isMobileDevice ? 140 : width, src: fulfillmentPlus_logo, preview: false }, void 0));
};
HandheldLogo.defaultProps = {
    clickable: false
};
export default HandheldLogo;
