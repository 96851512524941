var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Button from '@fp-suite/core-ui/src/components/Button';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
var ConfirmDropItem = function (_a) {
    var title = _a.title, confirmItem = _a.confirmItem, cancelItemConfirm = _a.cancelItemConfirm, hasBgStyle = _a.hasBgStyle;
    var t = useTranslation().t; // Use the hook to get the t function
    return (_jsxs("div", __assign({ className: hasBgStyle ? 'bg-white p-5 pb-0.5 rounded-lg shadow-sm' : '' }, { children: [_jsx("h1", __assign({ className: 'font-bold' }, { children: t(title) }), void 0), " ", _jsx(Form.Item, { children: _jsxs("div", __assign({ className: 'mt-5' }, { children: [_jsx("div", __assign({ className: 'w-max float-right' }, { children: _jsx(Button, { defaultColor: 'bg-red-500', buttonText: t('Cancel'), onClick: cancelItemConfirm }, void 0) }), void 0), _jsx("div", __assign({ className: 'w-max float-left' }, { children: _jsx(Button, { onClick: confirmItem, buttonText: t('Confirm') }, void 0) }), void 0)] }), void 0) }, void 0)] }), void 0));
};
ConfirmDropItem.defaultProps = {
    hasBgStyle: false
};
export default ConfirmDropItem;
