var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable */
import React from 'react';
import { Form } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import BackBtn from './BackBtn';
import { useTranslation } from 'react-i18next';
function ScanDataTotes(_a) {
    var scanCart = _a.scanCart, isToteScanned = _a.isToteScanned, scannedToteArrays = _a.scannedToteArrays, currentPickRunTypeId = _a.currentPickRunTypeId, errorMsgText = _a.errorMsgText, onToteScan = _a.onToteScan, totesList = _a.totesList, scannedLocationIncorrect = _a.scannedLocationIncorrect, setScannedLocationIncorrect = _a.setScannedLocationIncorrect, backBtn = _a.backBtn;
    var t = useTranslation().t;
    var form = Form.useForm()[0];
    var requiredPickContainerCount = localStorage.getItem('requiredPickContainerCount');
    requiredPickContainerCount *= 1;
    if (currentPickRunTypeId !== 'PrtSingle' && currentPickRunTypeId !== 'PrtOrderLevel') {
        requiredPickContainerCount = 1;
    }
    var onToteScanned = function (event) {
        if (event.key === 'Enter') {
            form.setFieldsValue({
                scannedTote: ''
            });
            onToteScan(event.target.value);
        }
        else {
            setScannedLocationIncorrect(false);
        }
    };
    var parsedContainers = localStorage.getItem('pickRunContainerList');
    parsedContainers = JSON.parse(parsedContainers);
    return (_jsx("div", { children: _jsxs("div", __assign({ className: 'bg-white p-5 pb-0.5 rounded-lg shadow-sm' }, { children: [_jsxs("div", __assign({ className: 'justify-between border-b-2 border-gray-300 mb-6 mt-2' }, { children: [_jsxs("div", __assign({ className: 'flex flex-row items-center mb-3' }, { children: [_jsx("h1", __assign({ className: 'text-lg text-gray-600 w-1/2' }, { children: t('Pick Run Id') }), void 0), _jsx("h1", __assign({ className: 'm-auto w-1/2 font-medium text-base' }, { children: localStorage.getItem('pickRunId') }), void 0)] }), void 0), _jsxs("div", __assign({ className: 'flex flex-row items-center mb-3' }, { children: [_jsx("h1", __assign({ className: 'text-lg text-gray-600 w-1/2' }, { children: t('Cart') }), void 0), _jsx("h1", __assign({ className: 'm-auto w-1/2 font-medium text-base' }, { children: localStorage.getItem('cartId') }), void 0)] }), void 0)] }), void 0), _jsxs("h1", __assign({ className: 'font-bold mb-4' }, { children: [t('Recommended totes'), ": ", requiredPickContainerCount] }), void 0), _jsxs("div", __assign({ className: 'flex justify-between' }, { children: [_jsx(BackBtn, { onClick: backBtn }, void 0), _jsx("button", __assign({ onClick: onToteScan, className: 'bg-transparent rounded-md cursor-pointer', style: { borderColor: '#EB61A8' } }, { children: _jsx("div", __assign({ className: 'flex flex-row justify-center items-center ' }, { children: _jsx("div", __assign({ className: 'pb-1' }, { children: _jsx(RightOutlined, { style: { color: '#000', fontSize: '22px' } }, void 0) }), void 0) }), void 0) }), void 0)] }), void 0)] }), void 0) }, void 0));
}
export default ScanDataTotes;
