var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { BsCheckCircleFill } from 'react-icons/bs';
import { IoIosAddCircle } from 'react-icons/io';
import { useTranslation } from 'react-i18next'; // Importing i18n
import MainHeading from './MainHeading';
import { getScannedCart, getScannedTote, getScannedLocation, pickMovePickContainer } from '../redux/authSlice';
import Input from '@fp-suite/core-ui/src/components/Input';
import Button from '@fp-suite/core-ui/src/components/Button';
import Totes from './Totes';
import CancelBtn from './CancelBtn';
import ConfirmDropItem from './ConfirmDropItem';
var MoveToSorting = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var setIsLoading = _a.setIsLoading;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var form = Form.useForm()[0];
    var history = useHistory();
    var _h = useState(''), setScannedCartData = _h[1];
    var _j = useState(''), errorMsgText = _j[0], setErrorMsgText = _j[1];
    var _k = useState(false), setIsCartScanned = _k[1];
    var _l = useState(false), setIsToteScanned = _l[1];
    var _m = useState(false), scannedCartIncorrect = _m[0], setScannedCartIncorrect = _m[1];
    var _o = useState(false), scannedToteIncorrect = _o[0], setScannedToteIncorrect = _o[1];
    var _p = useState(false), scannedDropzoneLocationIncorrect = _p[0], setScannedDropzoneLocationIncorrect = _p[1];
    var _q = useState(false), scannedSortingPodLocationIncorrect = _q[0], setScannedSortingPodLocationIncorrect = _q[1];
    var _r = useState('scanCart'), currentActiveSubscreen = _r[0], setCurrentActiveSubscreen = _r[1];
    var _s = useState([]), facilitiesLocationList = _s[0], setFacilitiesLocationList = _s[1];
    var _t = useState([]), totesList = _t[0], setTotesList = _t[1];
    var cartScanData = localStorage.getItem('cartId');
    var dropzoneName = localStorage.getItem('dropzoneName');
    var _u = useState(''), prevActiveScreen = _u[0], setPrevActiveScreen = _u[1];
    var onCartScan = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(event.key === 'Enter')) return [3 /*break*/, 2];
                    form.setFieldsValue({
                        scannedCart: ''
                    });
                    setIsLoading(true);
                    return [4 /*yield*/, dispatch(getScannedCart({
                            scanId: event.target.value,
                            setScannedCartIncorrect: setScannedCartIncorrect,
                            setCurrentActiveSubscreen: setCurrentActiveSubscreen,
                            setCartScannedData: setScannedCartData,
                            setErrorMsgText: setErrorMsgText,
                            setIsCartScanned: setIsCartScanned,
                            subscreenName: 'scanDropzoneLocation'
                        }))];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    setScannedCartIncorrect(false);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onDropzoneLocationScan = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(event.key === 'Enter')) return [3 /*break*/, 2];
                    form.setFieldsValue({
                        scannedDropzoneLocation: ''
                    });
                    setIsLoading(true);
                    return [4 /*yield*/, dispatch(getScannedLocation({
                            scanLocationId: event.target.value,
                            setScannedLocationIncorrect: setScannedDropzoneLocationIncorrect,
                            setCurrentActiveSubscreen: setCurrentActiveSubscreen,
                            fromScreen: 'dropzoneScreen',
                            subscreenName: 'scanTote',
                            setErrorMsgText: setErrorMsgText,
                            totesList: totesList,
                            setTotesList: setTotesList,
                            setFacilitiesLocationList: setFacilitiesLocationList
                        }))];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    setScannedDropzoneLocationIncorrect(false);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onSortingPodLocationScan = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(event.key === 'Enter')) return [3 /*break*/, 2];
                    form.setFieldsValue({
                        scannedSortingPodLocation: ''
                    });
                    setIsLoading(true);
                    return [4 /*yield*/, dispatch(getScannedLocation({
                            scanLocationId: event.target.value,
                            setScannedLocationIncorrect: setScannedSortingPodLocationIncorrect,
                            setCurrentActiveSubscreen: setCurrentActiveSubscreen,
                            fromScreen: 'sortingPodScreen',
                            subscreenName: 'penultimateStep',
                            setErrorMsgText: setErrorMsgText,
                            totesList: totesList,
                            setTotesList: setTotesList
                        }))];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    setScannedSortingPodLocationIncorrect(false);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onToteScan = function (event, isFromFinalStep, subscreenName) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(event.key === 'Enter')) return [3 /*break*/, 2];
                    form.setFieldsValue({
                        scannedTote: ''
                    });
                    setIsLoading(true);
                    return [4 /*yield*/, dispatch(getScannedTote({
                            scanToteId: event.target.value,
                            setScannedToteIncorrect: setScannedToteIncorrect,
                            totesList: totesList,
                            setCurrentActiveSubscreen: setCurrentActiveSubscreen,
                            setErrorMsgText: setErrorMsgText,
                            setTotesList: setTotesList,
                            setIsToteScanned: setIsToteScanned,
                            isFromFinalStep: isFromFinalStep,
                            fromMoveToSortingScreen: true,
                            subscreenName: subscreenName,
                            facilitiesLocationList: facilitiesLocationList
                        }))];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    setScannedToteIncorrect(false);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var parsedPodDetailsArray = localStorage.getItem('podsDetailsArray');
    parsedPodDetailsArray = JSON.parse(parsedPodDetailsArray);
    var currIndexInPodDetailsList = localStorage.getItem('currIndexInPodDetailsList');
    if (!currIndexInPodDetailsList) {
        currIndexInPodDetailsList = 0;
    }
    else {
        currIndexInPodDetailsList *= 1;
    }
    return (_jsxs("div", __assign({ className: 'px-4 sm:px-8 lg:px-16 pt-1 lg:pt-3 pb-16 h-screen w-full sm:w-4/5 md:w-3/5 lg:w-2/5 mx-auto' }, { children: [_jsx(MainHeading, { heading: t('Move To Sorting') }, void 0), _jsx("div", __assign({ className: 'py-7 px-10 w-full rounded-xl shadow-sm bg-white' }, { children: currentActiveSubscreen === 'scanCart' ? (_jsxs("div", { children: [_jsx(Form, __assign({ form: form }, { children: _jsx(Form.Item, __assign({ validateStatus: scannedCartIncorrect ? 'error' : '', help: scannedCartIncorrect && errorMsgText, className: 'w-full', name: 'scannedCart' }, { children: _jsx(Input, { placeholder: '', title: t('Scan Cart'), onKeyDown: onCartScan, autoFocus: true, allowClear: true }, void 0) }), void 0) }), void 0), _jsx(CancelBtn, { title: t('Cancel'), onBtnClick: function () {
                                history.push('/');
                            } }, void 0)] }, void 0)) : currentActiveSubscreen === 'scanDropzoneLocation' ? (_jsxs("div", { children: [_jsxs("div", __assign({ className: 'flex flex-row items-center border-b-2 border-gray-300 mb-6' }, { children: [_jsx("h1", __assign({ className: 'mb-3 text-base text-gray-600' }, { children: t('Cart') }), void 0), _jsx("h1", __assign({ className: 'mx-auto w-max' }, { children: cartScanData }), void 0)] }), void 0), _jsx(Form, __assign({ form: form }, { children: _jsx(Form.Item, __assign({ validateStatus: scannedDropzoneLocationIncorrect ? 'error' : '', help: scannedDropzoneLocationIncorrect && errorMsgText, className: 'w-full', name: 'scannedDropzoneLocation' }, { children: _jsx(Input, { placeholder: '', title: t('Scan Dropzone Location'), onKeyDown: onDropzoneLocationScan, autoFocus: true, allowClear: true }, void 0) }), void 0) }), void 0), _jsx(CancelBtn, { title: t('Cancel'), onBtnClick: function () {
                                setCurrentActiveSubscreen('scanCart');
                            } }, void 0)] }, void 0)) : currentActiveSubscreen === 'scanTote' ? (_jsxs("div", { children: [_jsxs("div", __assign({ className: 'flex flex-row items-center' }, { children: [_jsx("h1", __assign({ className: 'mb-3 text-base text-gray-600 w-1/2' }, { children: t('Cart') }), void 0), _jsx("h1", __assign({ className: 'w-1/2' }, { children: cartScanData }), void 0)] }), void 0), _jsxs("div", __assign({ className: 'flex flex-row items-center border-b-2 border-gray-300 mb-6 mt-1' }, { children: [_jsx("h1", __assign({ className: 'mb-3 text-base text-gray-600 w-1/2' }, { children: t('Dropzone') }), void 0), _jsx("h1", __assign({ className: 'w-1/2' }, { children: dropzoneName }), void 0)] }), void 0), _jsx(Form, __assign({ form: form }, { children: _jsx(Form.Item, __assign({ validateStatus: scannedToteIncorrect ? 'error' : '', help: scannedToteIncorrect && errorMsgText, className: 'w-full', name: 'scannedTote' }, { children: _jsx(Input, { placeholder: '', title: t('Scan Tote'), onKeyDown: function (e) { return onToteScan(e, false, 'scanSortingPodLocation'); }, autoFocus: true, allowClear: true }, void 0) }), void 0) }), void 0), _jsx(CancelBtn, { title: t('Cancel'), onBtnClick: function () {
                                setTotesList([]);
                                localStorage.removeItem('containerIdStr');
                                setCurrentActiveSubscreen('scanDropzoneLocation');
                            } }, void 0), _jsx("h1", __assign({ className: 'my-3.5 font-medium text-gray-500' }, { children: t('Place Totes on Cart. When Cart is full, click next') }), void 0), totesList &&
                            totesList.length > 0 &&
                            totesList.map(function (tote) { return _jsx(Totes, { toteData: tote.toteData, counter: tote.count }, void 0); }), _jsx("div", __assign({ className: 'mt-3' }, { children: _jsx(Button, { buttonText: t('Next'), isDisabled: totesList.length === 0, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                setIsLoading(true);
                                                return [4 /*yield*/, dispatch(pickMovePickContainer({
                                                        containerId: localStorage.getItem('containerIdStr'),
                                                        fromCart: true,
                                                        setCurrentActiveSubscreen: setCurrentActiveSubscreen,
                                                        subscreenName: 'scanSortingPodLocation'
                                                    }))];
                                            case 1:
                                                _a.sent();
                                                setIsLoading(false);
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } }, void 0) }), void 0)] }, void 0)) : currentActiveSubscreen === 'scanSortingPodLocation' ? (_jsxs("div", { children: [_jsxs("div", __assign({ className: 'flex flex-row items-center border-b-2 border-gray-300 mb-6' }, { children: [_jsx("h1", __assign({ className: 'mb-3 text-base text-gray-600' }, { children: t('Cart') }), void 0), _jsx("h1", __assign({ className: 'mx-auto w-max' }, { children: cartScanData }), void 0)] }), void 0), _jsxs("div", __assign({ className: 'my-3.5' }, { children: [_jsx("h1", __assign({ className: 'mb-2 font-bold text-md' }, { children: t('Go To Location') }), void 0), _jsxs("table", __assign({ className: ' border-collapse border border-gray-400' }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: 'border border-gray-400 p-2 text-gray-400 font-medium' }, { children: t('Area') }), void 0), _jsx("th", __assign({ className: 'border border-gray-400 p-2 text-gray-400 font-medium ' }, { children: t('Sorting Pod') }), void 0)] }, void 0) }, void 0), _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: 'border  border-gray-400 text-center' }, void 0), _jsx("td", __assign({ className: 'border border-gray-400 text-center ' }, { children: parsedPodDetailsArray &&
                                                            parsedPodDetailsArray.length > 0 &&
                                                            ((_b = parsedPodDetailsArray[currIndexInPodDetailsList]) === null || _b === void 0 ? void 0 : _b.podName) }), void 0)] }, void 0) }, void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: 'mb-3.5' }, { children: [_jsx("h1", __assign({ className: 'mb-2 font-bold text-md' }, { children: t('To Drop') }), void 0), parsedPodDetailsArray &&
                                    parsedPodDetailsArray.length > 0 &&
                                    ((_c = parsedPodDetailsArray[currIndexInPodDetailsList]) === null || _c === void 0 ? void 0 : _c.totes) &&
                                    ((_d = parsedPodDetailsArray[currIndexInPodDetailsList]) === null || _d === void 0 ? void 0 : _d.totes.map(function (item, index) { return (_jsxs("div", __assign({ className: 'flex flex-row items-center' }, { children: [_jsxs("h1", __assign({ className: 'font-semibold text-gray-400 mr-5' }, { children: [t('Tote'), " ", index + 1] }), void 0), _jsx("h1", { children: item.serialNumber }, void 0)] }), void 0)); }))] }), void 0), _jsx(Form, __assign({ form: form }, { children: _jsx(Form.Item, __assign({ validateStatus: scannedSortingPodLocationIncorrect ? 'error' : '', help: scannedSortingPodLocationIncorrect && errorMsgText, className: 'w-full', name: 'scannedSortingPodLocation' }, { children: _jsx(Input, { placeholder: '', title: t('Scan Sorting Pod Location'), onKeyDown: onSortingPodLocationScan, autoFocus: true, allowClear: true }, void 0) }), void 0) }), void 0), _jsx(CancelBtn, { title: t('Cancel'), onBtnClick: function () {
                                setPrevActiveScreen('scanSortingPodLocation');
                                setCurrentActiveSubscreen('confirmCancel');
                            } }, void 0)] }, void 0)) : currentActiveSubscreen === 'penultimateStep' ? (_jsxs("div", { children: [_jsxs("div", __assign({ className: 'flex flex-row items-center' }, { children: [_jsx("h1", __assign({ className: 'mb-3 text-base text-gray-600 w-1/2' }, { children: t('Cart') }), void 0), _jsx("h1", __assign({ className: 'w-1/2' }, { children: cartScanData }), void 0)] }), void 0), _jsxs("div", __assign({ className: 'flex flex-row items-center border-b-2 border-gray-300 mb-6 mt-1' }, { children: [_jsx("h1", __assign({ className: 'mb-3 text-base text-gray-600 w-1/2' }, { children: t('Pod') }), void 0), _jsx("h1", __assign({ className: 'w-1/2' }, { children: parsedPodDetailsArray &&
                                        parsedPodDetailsArray.length > 0 &&
                                        ((_e = parsedPodDetailsArray[currIndexInPodDetailsList]) === null || _e === void 0 ? void 0 : _e.podName) }), void 0)] }), void 0), _jsxs("div", __assign({ className: 'mb-3.5' }, { children: [_jsx("h1", __assign({ className: 'mb-2 font-bold text-md' }, { children: t('Scan Tote') }), void 0), parsedPodDetailsArray &&
                                    parsedPodDetailsArray.length > 0 &&
                                    ((_f = parsedPodDetailsArray[currIndexInPodDetailsList]) === null || _f === void 0 ? void 0 : _f.totes) &&
                                    ((_g = parsedPodDetailsArray[currIndexInPodDetailsList]) === null || _g === void 0 ? void 0 : _g.totes.map(function (item, index) {
                                        var _a;
                                        var scannedPodItemsList = localStorage.getItem('scannedPodItemsList');
                                        if (!scannedPodItemsList) {
                                            scannedPodItemsList = {};
                                        }
                                        else {
                                            scannedPodItemsList = JSON.parse(scannedPodItemsList);
                                        }
                                        var currPodName = (_a = parsedPodDetailsArray[currIndexInPodDetailsList]) === null || _a === void 0 ? void 0 : _a.podName;
                                        var filteredItem = scannedPodItemsList[currPodName] &&
                                            scannedPodItemsList[currPodName].filter(function (innerItem) {
                                                return innerItem === item.containerId;
                                            });
                                        return (_jsxs("div", __assign({ className: 'flex flex-row items-center' }, { children: [filteredItem && filteredItem.length > 0 ? (_jsx(BsCheckCircleFill, { size: 20, color: '#57bb54' }, void 0)) : (_jsx(IoIosAddCircle, { size: 22, color: '#ed693b' }, void 0)), _jsxs("h1", __assign({ className: 'font-semibold text-gray-400 mr-5 ml-2' }, { children: [t('Tote'), " ", index + 1] }), void 0), _jsx("h1", { children: item.serialNumber }, void 0)] }), void 0));
                                    }))] }), void 0), _jsx(Form, __assign({ form: form }, { children: _jsx(Form.Item, __assign({ validateStatus: scannedToteIncorrect ? 'error' : '', help: scannedToteIncorrect && errorMsgText, className: 'w-full', name: 'scannedTote' }, { children: _jsx(Input, { placeholder: '', title: t('Scan Tote'), onKeyDown: function (e) { return onToteScan(e, true, 'finalStep'); }, autoFocus: true, allowClear: true }, void 0) }), void 0) }), void 0), _jsx(CancelBtn, { title: t('Cancel'), onBtnClick: function () {
                                setPrevActiveScreen('penultimateStep');
                                setCurrentActiveSubscreen('confirmCancel');
                            } }, void 0)] }, void 0)) : currentActiveSubscreen === 'confirmCancel' ? (_jsx(ConfirmDropItem, { title: t('Confirm cancel'), confirmItem: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var containerIdsList, scannedContainerIds, unscannedContainerStr;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    containerIdsList = localStorage.getItem('containerIdStr');
                                    containerIdsList = containerIdsList.split(',');
                                    scannedContainerIds = localStorage.getItem('scannedContainerIds');
                                    if (!scannedContainerIds) {
                                        scannedContainerIds = [];
                                    }
                                    else {
                                        scannedContainerIds = JSON.parse(scannedContainerIds);
                                    }
                                    unscannedContainerStr = '';
                                    containerIdsList.forEach(function (item) {
                                        if (scannedContainerIds.indexOf(item) <= -1) {
                                            if (!unscannedContainerStr) {
                                                unscannedContainerStr = item;
                                            }
                                            else {
                                                unscannedContainerStr += "," + item;
                                            }
                                        }
                                    });
                                    setIsLoading(true);
                                    return [4 /*yield*/, dispatch(pickMovePickContainer({
                                            containerId: unscannedContainerStr,
                                            fromCart: false,
                                            fromPod: false,
                                            setCurrentActiveSubscreen: setCurrentActiveSubscreen,
                                            setTotesList: setTotesList,
                                            subscreenName: 'scanTote'
                                        }))];
                                case 1:
                                    _a.sent();
                                    setIsLoading(false);
                                    return [2 /*return*/];
                            }
                        });
                    }); }, cancelItemConfirm: function () {
                        setCurrentActiveSubscreen(prevActiveScreen);
                    } }, void 0)) : (_jsxs("div", { children: [_jsx("h1", __assign({ className: 'font-bold' }, { children: t('Move complete!') }), void 0), _jsx("div", __assign({ className: 'mt-4' }, { children: _jsx(Button, { buttonText: t('Done'), onClick: function () {
                                    localStorage.removeItem('currIndexInPodDetailsList');
                                    localStorage.removeItem('scannedPodItemsList');
                                    localStorage.removeItem('scannedContainerIds');
                                    localStorage.removeItem('containerIdStr');
                                    localStorage.removeItem('podsDetailsArray');
                                    history.push('/');
                                } }, void 0) }), void 0)] }, void 0)) }), void 0)] }), void 0));
};
export default MoveToSorting;
