var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Image } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import fp_Stacked_Logo from '../images/fulfillmentPlus_stacked.svg';
import { loginUser, getSessionToken } from '../redux/authSlice';
import Input from '@fp-suite/core-ui/src/components/Input';
import Button from '@fp-suite/core-ui/src/components/Button';
function Login(_a) {
    var _this = this;
    var setIsLoading = _a.setIsLoading;
    var t = useTranslation().t; // Initialize the translation function
    var history = useHistory();
    var dispatch = useDispatch();
    return (_jsxs("div", __assign({ className: 'px-8 pt-8 pb-5 w-full lg:w-7/12 mx-auto rounded-3xl shadow-lg bg-white' }, { children: [_jsx("div", __assign({ className: 'w-max mx-auto' }, { children: _jsx(Image, { className: 'cursor-pointer', width: 140, src: fp_Stacked_Logo, preview: false }, void 0) }), void 0), _jsxs("div", __assign({ className: 'w-9/12 mx-auto' }, { children: [_jsx("h1", __assign({ className: 'text-xl sm:text-xl font-bold text-black mx-auto text-center' }, { children: t('Fulfillment Center') }), void 0), _jsx("h1", __assign({ className: 'text-xl sm:text-xl font-bold text-black mb-3 mx-auto text-center' }, { children: t('Log In') }), void 0)] }), void 0), _jsx("div", __assign({ className: 'w-11/12 sm:w-8/12 mx-auto' }, { children: _jsxs(Form, __assign({ name: 'normal_login', initialValues: {
                        remember: true
                    }, onFinish: function (values) { return __awaiter(_this, void 0, void 0, function () {
                        var data;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    setIsLoading(true);
                                    data = {
                                        email: values.email,
                                        password: values.password,
                                        history: history
                                    };
                                    return [4 /*yield*/, dispatch(getSessionToken())];
                                case 1:
                                    _a.sent();
                                    return [4 /*yield*/, dispatch(loginUser(data))];
                                case 2:
                                    _a.sent();
                                    setIsLoading(false);
                                    return [2 /*return*/];
                            }
                        });
                    }); } }, { children: [_jsx(Form.Item, __assign({ name: 'email', rules: [
                                {
                                    required: true,
                                    message: t('Email / Username is required') // Translated message
                                }
                            ] }, { children: _jsx(Input, { title: t('Email / Username'), placeholder: t('yourname@email.com'), showDot: true, allowClear: true }, void 0) }), void 0), _jsx(Form.Item, __assign({ name: 'password', rules: [
                                {
                                    required: true,
                                    message: t('Password is required') // Translated message
                                }
                            ] }, { children: _jsx(Input, { isPassword: true, inputType: 'password', title: t('Password'), placeholder: t('********'), showDot: true, allowClear: true }, void 0) }), void 0), _jsx(Form.Item, { children: _jsxs("div", __assign({ className: 'mx-auto' }, { children: [_jsx(Button, { buttonText: t('Sign In'), widthFull: true }, void 0), " "] }), void 0) }, void 0)] }), void 0) }), void 0)] }), void 0));
}
export default Login;
